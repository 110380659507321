import { Link } from 'gatsby'
import React from 'react'
import { Button } from 'react-bootstrap'
import { SimpleTabs } from '../../../components/Form'
import Layout from '../../../components/Layout'
import { vendorNavTo } from '../../../components/navigation'
import ViewListingTable from '../../../components/ViewListingsTable'

const Page = () => {
  return <Layout>
        <Layout.Title>
            <h5>My Listings</h5>
            <Button as={Link} variant="primary" className="d-flex align-items-center" to="/app/listings/create">Create Listing</Button>
        </Layout.Title>
        <Layout.CardGrey>
            <SimpleTabs elements={[{ label: 'Active' }]} selectedIndex={0} size="md" />
            <div className="card-body rounded-soft bg-white">
                <div className="container-fluid mb-4">
                    <ViewListingTable actionButtonLabel="View" actionButtonFunction={listingId => vendorNavTo.listingsDetailsPage(listingId)} isVendor={true} />
                </div>
            </div>
        </Layout.CardGrey>
    </Layout>
}

export default Page
